<template>
  <MiscellaneousCity dialog-name="Cities" />
</template>
<script>
import MiscellaneousCity from '@/views/app/miscellaneous/City'

export default {
  name: 'CityWrapper',
  components: {
    MiscellaneousCity
  }
}
</script>
